import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, DataTable } from 'hudl-uniform-ui-components';
import styles from '../styles/dataTable.module.scss';

const dataTableRowsSnippet = require('raw-loader!../../../../data/snippets/data-table-rows.example');
const dataTableSnippet = require('raw-loader!../../../../data/snippets/data-table.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <div className={styles.codeSnippetWrapper}>
        <CodeSnippet
          scope={{ React: React, DataTable: DataTable }}
          code={dataTableSnippet}
          platform="react"
          gitHubLink="datatable"
        />
      </div>

      <Section title="Props">
        <PropList>
          <PropListItem name="style" types={['oneOf', 'string']}>
            <Text>Determines the style of the data table. Acceptable values are:</Text>
            <List type="unordered">
              <li>
                <code>default</code> - (default)
              </li>
              <li>
                <code>freeform</code>
              </li>
              <li>
                <code>striped</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the data table. Acceptable values are:</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> - (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="showTableBorder" types={['bool']}>
            <Text>
              Determines whether a border surrounds the entire data table.
            </Text>
          </PropListItem>

          <PropListItem name="freezeFirst" types={['oneOf', 'string']}>
            <Text>
              Determines whether to freeze the first row or column when scrolling. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>row</code> - default
              </li>
              <li>
                <code>column</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="columnContentTypes" types={['arrayOf', 'string']}>
            <Text>
              The content types of each column in your data table. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>text</code>
              </li>
              <li>
                <code>numeric</code>
              </li>
              <li>
                <code>selectmark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="columnShowSortArrows" types={['arrayOf', 'bool']}>
            <Text>
              Whether or not each column in your data table should show sort arrows. This also switches on the sort functionality for a column when <code>true</code>.
            </Text>
          </PropListItem>

          <PropListItem name="columnDefaultSortIndex" types={['number']}>
            <Text>
              Pass in the index of the column you want a default sort applied to. Starts at 0.
            </Text>
          </PropListItem>

          <PropListItem name="columnDefaultSortDirection" types={['oneOf', 'string']}>
            <Text>
              Determines how you want your default sort applied. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>ascending</code> - default
              </li>
              <li>
                <code>descending</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="columnHeaders" types={['oneOf', 'string', 'element']}>
            <Text>
              The text to display in the colum headers.
            </Text>
          </PropListItem>

          <PropListItem name="rows" types={['array']}>
            <Text>
              The data to show. Each row must include an id and data like so:
            </Text>
            <CodeSnippet
              scope={{ React: React }}
              code={dataTableRowsSnippet}
              platform="react"
              id="dataTableRows"
              disableCodeEditing
            />
          </PropListItem>

          <PropListItem name="rowsChecked" types={['array']}>
            <Text>
              Determines which rows have their Select Marks checked.
            </Text>
          </PropListItem>

          <PropListItem name="rowsSelected" types={['array']}>
            <Text>
              Determines which rows are selected.
            </Text>
          </PropListItem>

          <PropListItem name="onSelectMarkClick" types={['func']}>
            <Text>
              Responds to a row's Select Mark being clicked. Passes back the id of the row. If the Select Mark in the header is clicked, 'all-rows-checked' is passed back.
            </Text>
          </PropListItem>

          <PropListItem name="onRowClick" types={['func']}>
            <Text>
              Responds to a row being clicked. Passes back the id of the row.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            Including <code>selectmark</code> as a column content type will automatically put a selectmark in that row, but your data must account for that (you can put an empty string in for that particular column).
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
